.App-footer {
	grid-row: 3;
	grid-column: 1;
	width: 100%;
	height: 100%;
	background-color: #333;
	z-index: 1;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}

.App-footer {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: 75% 25%;
	grid-template-columns: 50% 50%;
}

	.Footer-contact {
		grid-column: 1;
		grid-row: 1;
		width: 95%;
		height: 100%;
		margin-left: 5%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	
		.Contact-email {
			width: 100%;
			margin-bottom: 0px;
			font-size: calc(8px + 2vmin);
			font-weight: 700;
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
		
		.Contact-google-review {
			width: 100%;
			color: #f75e11;
			text-decoration: none;
			font-size: calc(6px + 2vmin);
			font-weight: 500;
			display: flex;
			justify-content: center;
			align-items: center;
		}

	.Footer-phone {
		grid-column: 2;
		grid-row: 1 / 3;
		width: 95%;
		height: 100%;
		margin-left: 5%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: calc(13px + 2vmin);
	}

		.Footer-phone a {
			color: #f4f4f4;
			text-decoration: none;
		}