.App-content {
	grid-row: 2;
	grid-column: 1;
	width: 100vw;
	height: 100%;
	overflow: scroll;
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.App-panel-spacer {
	min-height: 25px;
	width: 100%;
}