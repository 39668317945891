.App-info-panel {
	color: #333;
	min-height: 200px;
	width: 95%;
	margin-top: 5%;
	padding-right: 2.5%;
	padding-left: 2.5%;
	padding-top: 5%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	box-sizing: border-box;
	flex-direction: column;
}

	.App-info-panel h2 {
		margin: 0px;
		width: 100%;
		font-size: calc(12px + 2vmin);
	}

	.App-info-panel p {
		width: 90%;
		font-size: calc(8px + 2vmin);
	}