.App-pdf-panel {
	color:  #333;
	min-height: 250px;
	width: 95%;
	padding-right: 2.5%;
	padding-left: 2.5%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}

	.App-pdf-panel h2 {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: calc(12px + 2vmin);
	}

	.App-pdf-panel ul {
		list-style-type:none;
		padding-left: 1.5%;
		padding-right: 1.5%;
  		margin: 0;
  		width: 97%;
  		height: 100%;
	}

	.App-pdf-panel li {
		margin-top: 15px;
		margin-left: 25px;
		font-size: calc(8px + 2vmin);
	}

		.pdf-link {
			color: #333;
			text-decoration: none;
		}

	.App-pdf-panel li:first-of-type {
		margin-top: 0px;
		margin-left: 25px;
	}

	.App-pdf-panel li::before {
		content: "› ";
		color: #f75e11;
	}