@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400&display=swap');

body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}