.App-contact-panel {
	min-height: 400px;
	width: 95%;
	padding-right: 2.5%;
	padding-left: 2.5%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	color: #333;
}

	.App-contact-panel h2{
		margin: 0px;
		width: 100%;
		text-align: center;
		font-size: calc(12px + 2vmin);
	}

	.contact-location {
		width: 90%;
		margin-top: 5px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

		.location-text {
			width: 100%;			
		}

			.location-text p {
				margin: 0px;
				margin-bottom: 5px;
				font-size: calc(6px + 2vmin);
			}

			.location-text-hours {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding-bottom: 5px;
			}

			.location-text-address {
				width: 100%;
				text-align: center;
			}

		.location-map {
			width: 100%;
			min-height: 200px;
			margin-top: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

			.location-map iframe {
				border: 0px;
			}