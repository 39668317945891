.App-header {
	grid-row: 1;
	grid-column: 1;
	width: 95vw;
	min-height: 100%;
	color: #f4f4f4;
	background-color: rgba(0, 22, 145,.9);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding-left: 5vw;
	z-index: 1;
}
	.App-header h1 {
		margin: 0px;
		padding: 0px;
		font-size: calc(18px + 2vmin);
	}
	.App-header h2 {
		margin: 0px;
		padding: 0px;
		font-size: calc(10px + 2vmin);
	}